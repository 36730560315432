<template>
  <div class="home-banner" ref="banner">
    <div class="banner-content">
      <div class="container is-max-widescreen">
        <div class="is-flex is-justify-content-space-between is-align-items-flex-end">
          <div class="banner-content-overlay">
            <div class="banner-title mb-2" v-html="$t('banner.title')"></div>
            <div class="is-size-4 is-size-6-mobile">
              <p v-html="$t('banner.desc')" class="banner-desc"></p>
            </div>
            <div class="banner-action is-flex">
              <b-button type="is-primary" tag="a" href="#contact-us">{{ $t('contact_us') }}</b-button>
              <b-button type="is-white" outlined class="learn-more ml-4" @click="learnMore">{{ $t('learn_more') }}</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="banner-wrapper is-flex" :class="{
      'is-portrait': isPortrait
    }">
      <img src="/assets/img/banner/banner.jpg" alt="Sgara Banner" class="banner-background">
      <img src="/assets/img/banner/banner-image.png" alt="Sgara Farmer" class="banner-image">
    </div>
  </div>
</template>

<script>

export default {
  name: "Banner",
  components: {},
  data: () => {
    return {}
  },
  computed: {
    isPortrait() {
      return window.innerHeight > window.innerWidth
    }
  },
  methods: {
    // onPlayerReady() {
    //   this.$refs.yt.player.playVideo()
    // },
    learnMore() {
      window.scrollTo({
        top: this.$refs.banner.offsetHeight,
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style lang="scss">
.home-banner {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: '';
    background-color: rgba(0, 0, 0, .6);
    z-index: 2;
  }

  .banner-title {
    font-size: 50px;
    @media screen and (max-width: 1024px) {
      font-size: 2rem;
    }

    > span {
      font-weight: bold;
    }
  }

  .banner-content {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    color: #FFFFFF;
    z-index: 3;

    .banner-content-overlay {
      max-width: 850px;
      width: 100%;
    }
  }

  .banner-background {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }

  .banner-image {
    position: absolute;
    z-index: 2;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    height: 500px;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .banner-action {
    margin-top: 2.1rem;
  }

  .button {
    padding: .975rem 2.125rem;
    border-width: 2px!important;
    @media screen and (max-width: 1024px) {
      padding: .725rem 1.125rem;
    }
  }

  .learn-more {

    &:hover {
      background-color: #FFFFFF;
      color: #2c3e50;
    }
  }
  /*
  .banner-img {
    position: absolute;
    transition: all .5s;
    opacity: 0;

    &.active {
      opacity: 1;
    }
  }
   */

  .banner-wrapper {
    position: relative;
    //padding-bottom: 56.25%;
    /* 16:9 */
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .banner-wrapper iframe {
    position: absolute;
    //top: 0;
    //left: 0;
    width: 140%;
    height: 140%;
    top: -20%;
    left: -20%;
  }

  .banner-wrapper.is-portrait iframe {
    position: absolute;
    //top: 0;
    //left: 0;
    width: 360%;
    height: 300%;
    top: -100%;
    left: -100%;
  }

}
</style>